import React from 'react';
import {
  Box,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import GamePageLinks from '../components/game-page-links';
import ImageCard from '../components/image-card';
import Layout from '../components/layout';
import CrabsBoxart from '../assets/crabs-in-a-bucket/game-crabs-boxart-notext.png';
import NewsletterSignup from '../components/newsletter-signup';

const links = {
  social: {
    name: "Game Social",
    path: {
      instagram: "https://www.instagram.com/bluerondogames/",
      facebook: "https://www.facebook.com/bluerondogames/",
      twitter: "https://www.twitter.com/bluerondogames/",
      discord: "",
    }
  },
};

const description = "To those who may seek what is within,\
  \nThe Four Crab Kingdoms have ruled the Isavel Ocean for far too long! Help us free the Crabfolk in this action-packed party game. Challenge your friends and family in a contest of wits to free the Four Crab Kingdoms with a colorful cast of unique Joker Crabs based on real-life crab species. Use the power of the Joker Crabs wisely, beware of the Royal Crabs, and trick, twist, and pinch your way to victory. We are counting on you to lead the way!"

const styling = (theme) => ({
  body: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  description: {
    maxWidth: 900,
    padding: '8px 16px',
    textAlign: 'justify',
  },
  hero: {
    maxHeight: 400,
    overflow: 'hidden',
    padding: 0,
    position: 'relative',
  },
  heroImg: {
    minHeight: 400,
    marginTop: '-20%',
  },
  title: {
    color: theme.palette.common.white,
    paddingLeft: 12,
  },
  titleBanner: {
    background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 95%, rgba(255,255,255,0) 100%)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
  },
  titleBannerTopShadow: {
    background: 'linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(255,255,255,0) 50%)',
    height: 50,
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  }
});

const CrabsGamePage = () => {
  // Styling/Theming
  const classes = makeStyles(styling)();
  
  return (
    <Layout title="Crabs in a Bucket">
      <ImageCard
        classes={{
          root: classes.hero,
          image: classes.heroImg,
        }}
        src={CrabsBoxart}
      >
        <Box className={classes.titleBannerTopShadow} />
        <Box className={classes.titleBanner}>
          <Typography className={classes.title} variant="h1">Crabs in a Bucket</Typography>
        </Box>
      </ImageCard>
      <Box className={classes.body}>
        {description.split('\n').map((item, i) => {
          return <Typography className={classes.description} key={i}>{item}</Typography>;
        })}
        <NewsletterSignup />
      </Box>
      <GamePageLinks routes={links}/>
    </Layout>
  );
}

export default CrabsGamePage
